<template>
<div>
    <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Client Report</h2>
    <div class="row mb-3">
        <div class="col-12 text-right">
            <div class="form-inline">
                <div class="form-group mr-1">
                    <input type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                </div>
                <div class="form-group mr-1">
                    <input type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                </div>
                <button
                    type="button"
                    class="btn text-white mr-1"
                    @click="getDetails"
                    style="background-color: #383838"
                >
                    <i class="fa fa-search" title="Search"></i>
                    Search
                </button>
                <button
                    type="button"
                    class="btn btn-success mr-1"
                    v-if="showExportButton"
                    @click="exportToExcel"
                >
                    <i
                        class="fa fa-download"
                        title="Export"
                    >
                    </i>
                    Export
                </button>
            </div>
        </div>
    </div>
    <div class="row mb-12" v-if="reportLoading">
        <div class="col-12">
            <div class="card">
                <div class="card-body text-center">
                    <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                    <h4>Processing..............</h4>
                </div>
            </div>
        </div>
    </div>
    <div v-else >
        <div class=" container col-12">
            <div class="row">
                <div class="  pr-4 col-2">
                    <p class="mb-1">Client Name: </p>
                    <p class="mb-1">Dates:</p>
                    <p class="mb-1"># of clicks Requester:</p>
                    <p class="mb-1">Campaign Dates:</p>
                    <p class="mb-1">Targets</p>
                    <p class="mb-1">Link Level</p>
                    <p class="mb-1">Tos</p>
                    <p class="mb-1">Tos Total</p>
                    <p class="mb-1">Tos Average</p>
                    <p class="mb-1">Tos Total to Deliver</p>
                    <div class="pt-3">
                        <p class="mb-1"># of clicks Requester:</p>
                        <p class="mb-1"># of clicks Delivered:</p>
                        <p class="mb-1">% of clicks Delivered:</p>
                    </div>


                </div>
                <div class=" container col-2" v-if="report != '' ">
                    <p class="mb-1">{{report.clientName}} </p>
                    <p class="mb-1">{{report.datePeriod}}</p>
                    <p class="mb-1">{{report.numberOfClicksRequested}}</p>
                    <p class="mb-1">{{report.campaignDates}}</p>
                    <p class="mb-1">{{target}}</p>
                    <p class="mb-1">{{report.linkLevel}}</p>
                    <p class="mb-1">{{report.tos}}</p>
                    <p class="mb-1">{{report.totalTosTotal}}</p>
                    <p class="mb-1">{{report.totalTosAverage}}</p>
                    <p class="mb-1">{{report.totalToDeliver}}</p>

                    <div class="pt-3">
                        <p class="mb-1">{{report.numberOfClicksRequested}}</p>
                        <p class="mb-1">{{report.totalClicksDelivered}}</p>
                        <p class="mb-1">{{report.totalClicksPercentage.toFixed(1)}}</p>
                    </div>

                </div>
                <div class=" mt-3 container col-8"></div>

            </div>

        </div>
        <div class="row">
            <div class="col-12 pt-3">
                <ejs-grid
                    ref="grid"
                    id="Grid"
                    :dataSource="reports"
                    :allowExcelExport='true'
                    :allowPaging="true"
                    :allowSorting="true"
                    :allowFiltering="true"
                    :allowResizing='true'
                    :filterSettings="filterSettings"
                    :pageSettings='pageSettings'
                >
                    <e-columns>
                        <e-column field='date' minWidth='8' width='100' headerText="Date" format='yMd' type='date'></e-column>
                        <e-column field='target' minWidth='8' width='100' headerText="Target"></e-column>
                        <e-column field='attempts' minWidth='8' width='110' headerText='Attempts'></e-column>
                        <e-column field='success' minWidth='8' width='210' headerText='Success'></e-column>
                        <e-column field='fail' minWidth='8' width='180' headerText='Fail'></e-column>
                        <e-column field='successp' headerText='Success Percentage' minWidth='8' width='110'></e-column>
                        <e-column field='clickReq' headerText='Click Requested' minWidth='8' width='110'></e-column>
                        <e-column field='tosAvg' headerText='TOS Average' minWidth='8' width='110'></e-column>
                        <e-column field='tosTotal' headerText='TOS Total' minWidth='8' width='110'></e-column>

                        <!--                        <e-column field='proxy_ip' headerText='Proxy Ip' minWidth='8' width='100'></e-column>-->
                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <e-column type="Sum" field="success" :footerTemplate='footerSum'></e-column>
                                <e-column type="Sum" field="attempts" :footerTemplate='footerSum'></e-column>
                                <e-column type="Sum" field="fail" :footerTemplate='footerSum'></e-column>
                                <e-column type="Sum"  field='clickReq' :footerTemplate='footerSum' ></e-column>
<!--                                <e-column type="Sum" field='tosAvg' :footerTemplate='footerSum' ></e-column>-->
<!--                                <e-column type="Sum"  field='tosTotal' :footerTemplate='footerSum' ></e-column>-->

                                <e-column type="Average" field="successp" :footerTemplate='footerAverage'>

                                </e-column><e-column type="Average" field="target" :footerTemplate='footerT'></e-column>
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </div>
        </div>

    </div>
</div>
</template>

<script>

import {ExcelExport, Aggregate , Filter, GridPlugin, Group, Page, Resize, Sort} from "@syncfusion/ej2-vue-grids";
import axios from "axios";
import Vue from "vue";
Vue.use(GridPlugin);
export default {
name: "Result.Reporting",
    data: function () {
        return {
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>{{data.Sum}}</span>`,
                        data () {return { data: {data: {}}};}
                    })
                }
            },
            footerAverage: function () {
                return  { template : Vue.component('averageTemplate', {
                        template: `<span>{{data.Average}}</span>`,
                        data () {return { data: {data: {}}};}
                    })
                }
            },
            footerT: function () {
                return  { template : Vue.component('averageTemplate', {
                        template: `<span>Total</span>`,
                        data () {return { data: {data: {}}};}
                    })
                }
            },
            filter:{
                to: '',
                from: '',
            },

           target:'',
            report:[],
            reports:[],

            proxyDataset: {},
            pageSettings: {pageSize: 100},

            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            searching: false,
            showExportButton: false,
            reportLoading: false,
        }
    },
    created: function(){



        window.addEventListener("keydown",this.windowListener);
        this.$root.preloader = false;
    },

    provide: {
        grid: [
            Page,
            Sort,
            ExcelExport,
            Group,
            Filter,
            Resize,
            Aggregate
        ]
    },
    methods:{
        windowListener: function(e){
            if(e.keyCode === 13 && !this.searching){
                this.search();
            }
        },
        actionHandler: function(args) {
            console.log(args);
        },
        getDetails: async function (){
            this.reportLoading = true
            this.filter.domain_id = this.$route.query.dom_id;
            this.filter.product_type = this.$route.query.product_type
            let result = await   axios.get(`${this.$root.serverUrl}/admin/campaigns/report/click`, {params:this.filter})
            this.report = result.data.data;
            this.target = this.report.target | ''
            if(result){
                this.reportLoading = false
            }
            console.log(this.report )


            this.reports = []
              for(let x in this.report){

            for(let i in this.report.dailyBreakdown){
                console.log(this.report[x].dailyBreakdown[i])
                let sucessP =  this.report[x].dailyBreakdown[i].delivered / this.report[x].dailyBreakdown[i].requested * 100
                this.reports.push({date:this.report.dailyBreakdown[i].days,target:this.report[x].target
                ,attempts:this.report[x].dailyBreakdown[i].requested,
                success:this.report[x].dailyBreakdown[i].delivered,
                    tos:this.report[x].dailyBreakdown[i].tos,
                fail:this.report[x].dailyBreakdown[i].requested -this.report[x].dailyBreakdown[i].delivered,
                successp:sucessP.toFixed(0),
                    clickReq:this.report[x].dailyBreakdown[i].clicksRequested,
                    tosAvg:this.report[x].dailyBreakdown[i].tosAvg,
                    tosTotal: this.report[x].dailyBreakdown[i].tosTotal
                })
            }
        }
        console.log( this.reports)

        },


        exportToExcel:function(){
            let fileName = `Export ${this.filter.from} to ${this.filter.to} ${this.filter.campaign} ${this.filter.client}`;
            fileName = fileName.replace(/ /g,"_");
            fileName = fileName.replace(/:/g,"_");
            fileName += ".xlsx";
            let excelExportProperties = {
                fileName,
                dataSource:this.reports,
                includeHiddenColumn: true
            };
            this.$refs.grid.excelExport(excelExportProperties);
        },



    }
}

</script>

<style scoped>

</style>
